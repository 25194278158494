// src/axios.js
import axios from 'axios';

// Load environment variables from .env file
require('dotenv').config();

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || 'https://malacatesjacal.io.gt/api', // Use environment variable or default URL
});

// Add a request interceptor to include the Bearer token
axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token'); // Replace 'token' with your token key
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default axiosInstance;